import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Business-Related Regulations",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {
      
      filesrcs: [
        { title: 'NASARAWA STATE FIBER OPTIC DEPLOYMENT RIGHT OF WAY REGULATION 2', src: 'https://api.nasida.na.gov.ng/services/NASIDA/NASARAWA STATE FIBER OPTIC DEPLOYMENT RIGHT OF WAY REGULATION 2.pdf', time:'Dec 24, 2024' },
        
        { title: 'PROCEDURE FOR CONTRACTORS REGISTRATION', src: 'https://api.nasida.na.gov.ng/services/NASIDA/PROCEDURE FOR CONTRACTORS REGISTRATION.pdf', time:'Dec 24, 2024' },
        { title: 'PROCEDURE FOR OBTAINING BUILDING PERMIT', src: 'https://api.nasida.na.gov.ng/services/NASIDA/PROCEDURE FOR OBTAING BUILDING PERMIT.pdf', time:'Dec 24, 2024' },
        { title: 'Procedure for Obtaining Forest Produce Permit ', src: 'https://api.nasida.na.gov.ng/services/NASIDA/Procedure for Obtaining Forest Produce Permit.pdf', time:'Dec 24, 2024' },
        { title: 'Procedure for Obtaining Right of Way (ROW) Permit', src: 'https://api.nasida.na.gov.ng/services/NASIDA/Procedure for Obtaining Right of Way (ROW) Permit.pdf', time:'Dec 24, 2024' },
        { title: 'Procedure for Obtaining Waste Management Services Approval', src: 'https://api.nasida.na.gov.ng/services/NASIDA/Procedure for Obtaining Waste Management Services Approval.pdf', time:'Dec 24, 2024' },
        { title: 'PROCEDURE FOR PROCESSING COFO AND DATA', src: 'https://api.nasida.na.gov.ng/services/NASIDA/PROCEDURE FOR PROCESSING COFO AND DATA.pdf', time:'Dec 24, 2024' },
        { title: 'PROCEDURE FOR PROCESSING ROFO', src: 'https://api.nasida.na.gov.ng/services/NASIDA/PROCEDURE FOR PROCESSING ROFO.pdf', time:'Dec 24, 2024' },
        { title: 'Procedure for the Registration of Business Premises', src: 'https://api.nasida.na.gov.ng/services/NASIDA/Procedure for the Registration of Business Premises.pdf', time:'Dec 24, 2024' },
        { title: 'PROCEDURE FOR THE REGISTRATION OF PRIVATE SCHOOLS', src: 'https://api.nasida.na.gov.ng/services/NASIDA/PROCEDURE FOR THE REGISTRATION OF PRIVATE SCHOOLS.pdf', time:'Dec 24, 2024' },
        { title: 'PROCEDURE TO ACCESS PUBLIC WATER', src: 'https://api.nasida.na.gov.ng/services/NASIDA/PROCEDURE TO ACCESS PUBLIC WATER.pdf', time:'Dec 24, 2024' },
        { title: 'NASARAWA STATE SCHEDULE OF TRADE RELATED FEES AND LEVIES ON INTER-STATE MOVEMENT OF GOODS', src: 'https://api.nasida.na.gov.ng/services/PROCEDURES-OF-PAYMENT-OF-FEES-AND-LEVIES-FOR-INTER-STATE-TRADE1.pdf', time:'Dec 24, 2024' },
        // { title: '', src: 'https://api.nasida.na.gov.ng/services/NASIDA/', time:'Dec 24, 2024' },
      ],
    }
  }, 

  methods: {
    goto(url) {
      window.open(url);
    },
  },


});
